// var Masonry = require('masonry-layout')

(function () {
  'use strict';

  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
      document.createTextNode(
        '@-ms-viewport{width:auto!important}'
      )
    )
    document.querySelector('head').appendChild(msViewportStyle)
  }

})();


$(function(){

  var $container = $('.gallery-list');

  $container.imagesLoaded( function(){
    $container.packery({
      itemSelector : '.gallery-item'
    });
  });

  $('.menu-toggle').on('click', e => {
    e.preventDefault();

    $('.header nav').toggleClass('is-open');
  });

  $('.image-link').magnificPopup({
    type:'image',
    gallery: {
      enabled: true,
      preload: [1,2],
      tCounter: '<span class="mfp-counter">%curr% av %total%</span>'
    }
  });

});